import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout'

Vue.use(VueRouter)

// 初始化路由
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/login')
  },
  {
    path: '/404',
    component: () => import('@/views/error/404')
  },
  // 个人中心
  {
    path: '/account',
    component: Layout,
    children: [
      {
        path: 'personalCenter',
        name: 'personalCenter',
        component: () => import('@/views/account/personalCenter'),
        meta: {
          title: '个人中心',
          hiddenMenu: true,
          module: 'mainApp'
        }
      },
      {
        path: 'modifyPassword',
        name: 'modifyPassword',
        component: () => import('@/views/account/modifyPassword'),
        meta: {
          title: '修改密码',
          hiddenMenu: true,
          module: 'mainApp'
        }
      }
    ]
  },
  // 可视化大屏
  {
    path: '/largeScreen/carbonNeutralOverview',
    name: 'carbonNeutralOverview',
    component: () => import('@/views/largeScreen/carbonNeutralOverview'),
    meta: {
      title: '碳中和总览'
    }
  },
  {
    path: '/largeScreen/carbonEmission',
    name: 'carbonEmission',
    component: () => import('@/views/largeScreen/carbonEmission'),
    meta: {
      title: '运营碳排放'
    }
  },
  {
    path: '/largeScreen/lowCarbonEffect',
    name: 'lowCarbonEffect',
    component: () => import('@/views/largeScreen/lowCarbonEffect'),
    meta: {
      title: '低碳运营成效'
    }
  }
]
const createRouter = () => new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})
const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
