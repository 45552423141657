<template>
  <div :class="isFold ? 'sidebar isFold' : 'sidebar'">
    <!-- logo -->
    <div class="logo"> 
      <i :title="isFold ? '碳中和管理系统' : ''" />
      <span>碳中和<br/>管理系统</span>
    </div>

    <!-- 导航 -->
    <el-scrollbar>
      <ul>
        <li 
        v-for="(item, index) in menuList" 
        :key="index" 
        :class="index === currentIndex ? 'active' : ''"
        :title="isFold ? item.menuName : ''"
        @click="setMenu(item, index)">
          <router-link :to="joinPath(item)">
            <i class="iconfont" :class="item.menuIcon ? `icon-menu-${item.menuIcon}` : 'icon-menu-default'" />
            <span>{{ item.menuName }}</span>
          </router-link>
        </li>
      </ul>
    </el-scrollbar>

    <!-- 账号信息 -->
    <el-dropdown placement="right-end" trigger="click">
      <span class="user">
        <i class="icon-user" :title="isFold ? username : ''" />
        <span :title="username && username.length > 6 ? username : ''">{{ username }}</span>
      </span>
      <el-dropdown-menu :class="isFold ? 'isFold' : ''" slot="dropdown">
        <el-dropdown-item @click.native="toPersonalCenter">个人中心</el-dropdown-item>
        <el-dropdown-item @click.native="toModifyPassword">修改密码</el-dropdown-item>
        <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <!-- 收起 -->
    <div class="btn-fold" @click.stop="isFold = !isFold">
      <i :class="isFold ? 'el-icon-arrow-right' : 'el-icon-arrow-left'" :title="isFold ? '展开' : ''" />
      <span>收起</span>
    </div>
  </div>
</template>

<script>
import { joinPath } from '@/utils/index'
import db from '@/utils/localStorage'

export default {
  name: 'Sidebar',
  props: {
    username: {
      type: String,
      default: ''
    },
    menuList: {
      type: Array,
      default: () => []
    },
    currentIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isFold: false
    }
  },
  methods: {
    joinPath,
    async setMenu(item, index) {
      this.$emit('update:currentIndex', index)
      db.set('CURRENT_INDEX', index)
      const isFoldSub = JSON.parse(JSON.stringify(item.children)).length ? false : true
      this.$emit('update:isFoldSub', isFoldSub)
    },
    // 个人中心
    toPersonalCenter() {
      this.$emit('update:currentIndex', null)
      this.$router.push('/account/personalCenter')
    },
    // 修改密码
    toModifyPassword() {
      this.$emit('update:currentIndex', null)
      this.$router.push('/account/modifyPassword')
    },
    // 退出
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.replace('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 88px;
  color: #fff;
  text-align: center;
  background: linear-gradient(180deg, #38A067 0%, #246B4A 100%);
  * {
    position: relative;
  }
  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background: url(@/assets/images/sidebar.png) no-repeat 100% 100%;
  }
  .logo {
    width: 100%;
    height: 146px;
    padding-top: 20px;
    i {
      display: block;
      height: 40px;
      background: url(@/assets/images/logo.png) no-repeat 50% 50%;
      background-size: auto 100%;
    }
    span {
      display: block;
      padding-top: 10px;
      height: 78px;
      overflow: hidden;
      font-family: 'PangMenZhengDao';
      font-size: 14px;
      letter-spacing: 2px;
    }
  }
  ::v-deep .el-scrollbar {
    width: 100%;
    height: calc(100% - 282px);
    margin-top: 16px;
    margin-bottom: 16px;
    &__wrap {
      overflow-x: hidden;
    }
    ul {
      padding: 0 8px;
      li {
        min-height: 56px;
        padding: 8px 0;
        margin-bottom: 8px;
        color: rgba(255,255,255,0.6);
        cursor: pointer;
        &.active,
        &:hover {
          color: rgba(255,255,255);
          background: rgba(0,0,0,0.12);
          border-radius: 4px;
        }
        i {
          display: block;
          height: 16px;
        }
        span {
          display: block;
          padding-top: 7px;
        }
      }
    }
  }
  .el-dropdown {
    margin-bottom: 8px;
    font-size: 12px;
    color: #fff;
    .user {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 64px;
      cursor: pointer;
      .icon-user {
        display: inline-block;
        width: 32px;
        height: 32px;
        background: url(@/assets/images/icon-user.png) no-repeat 50% 50%;
      }
      span {
        max-width: 88px;
        padding: 6px 4px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .btn-fold {
    width: 44px;
    height: 20px;
    color: #077452;
    line-height: 20px;
    cursor: pointer;
    background: rgba(255,255,255,0.6);
    border-radius: 12px;
    span {
      display: inline-block;
      padding-right: 4px;
      margin-left: -1px;
      font-size: 10px;
      vertical-align: top;
    }
  }
  &.isFold {
    width: 60px;
    .logo,
    .el-scrollbar li,
    .user,
    .btn-fold {
      span {
        display: none;
      }
    }
    .logo {
      i {
        height: 36px;
      }
    }
    .el-scrollbar {
      li {
        min-height: 32px;
        margin-bottom: 32px;
      }
    }
    .btn-fold {
      width: 20px;
      i {
        margin-left: 1px;
      }
    }
  }
}
.el-dropdown-menu {
  left: 72px !important;
  padding: 6px 0;
  margin: 0;
  &__item {
    padding: 0 14px;
    font-size: 12px;
    color: #777;
    line-height: 30px;
  }
  ::v-deep .popper__arrow {
    margin-top: -10px !important;
  }
  &.isFold {
    left: 44px !important;
  }
}
</style>
