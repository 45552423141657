import { ajaxRequest } from '@/utils/http'

// 登录接口
export function login(data) {
  return ajaxRequest({
    url: '/auth/token/pwd',
    method: 'post',
    data
  })
}

// 登出接口
export function logout(data) {
  return ajaxRequest({
    url: '/auth/token/logout',
    method: 'post',
    data
  })
}

// 刷新token
export function refreshToken(data) {
  return ajaxRequest({
    url: '/auth/token/refresh',
    method: 'post',
    data
  })
}

// 获取账户列表(只有一个直接获取用户信息)
export function getAccountList(data) {
  return ajaxRequest({
    url: '/auth/user/token',
    method: 'put',
    data,
    hasStatusJudge: false
  })
}

// 选择账户
export function chooseUserAccount(data) {
  return ajaxRequest({
    url: '/auth/user/choose',
    method: 'put',
    data
  })
}

// 获取用户信息
export function getUserInfo(data) {
  return ajaxRequest({
    url: '/auth/user/refresh',
    method: 'post',
    data
  })
}