
import Layout from '@/layout'

// 遍历后台传来的路由字符串，转换为组件对象
export function filterAsyncRoutes(arr, deep = 0, metaIndex, parentIndex) {
  return arr.map((item, index) => {
    const routeItem = {
      name: item.menuUrl.split('/')[1] ?? item.menuUrl,
      path: deep === 0 ? '/' + item.menuUrl : item.menuUrl,
      meta: {
        id: item.id, // 定义给后端用
        icon: item.menuIcon,
        title: item.menuName,
        menuIndex: deep === 0 ? index : metaIndex, // 同级的顺序
        topIndex: deep === 0 ? index : parentIndex, // 一级的index
        hidden: item.showFlag === 0, // 是否显示
        hiddenMenu: item.leftShowFlag === 0, // 是否显示左侧菜单
        query: item.urlParams ? parseQuery(item.urlParams) : null,
        module: item.module // 所属应用模块
      }
    }
    if (item.component) {
      if (item.component === 'Layout') {
        routeItem.component = Layout
      } else {
        routeItem.component = null
        routeItem.subComponent = item.component
      }
    } else if (!item.component || item.component === '') {
      routeItem.component = {
        render(h) {
          return h('router-view')
        }
      }
    }
    if (item.children && item.children.length) {
      routeItem.children = filterAsyncRoutes(item.children, deep + 1, routeItem.meta.menuIndex, routeItem.meta.topIndex)
    }
    return routeItem
  })
}
// 解析路由链接参数
function parseQuery(query) {
  const res = {}
  query = query.trim().replace(/^(\?|#|&)/, '')
  if (!query) {
    return res
  }
  query.split('&').forEach(param => {
    const parts = param.replace(/\+/g, ' ').split('=')
    const key = decodeURIComponent(parts.shift())
    const val = parts.length > 0 ? decodeURIComponent(parts.join('=')) : null
    if (res[key] === undefined) {
      res[key] = val
    } else if (Array.isArray(res[key])) {
      res[key].push(val)
    } else {
      res[key] = [res[key], val]
    }
  })
  return res
}
// 拼接路由
export function joinPath(item) {
  let path = `/${item.menuUrl}`
  if (item.children.length) {
    path += `/${item.children[0].menuUrl}`
    if (item.children[0].children.length) {
      path += `/${item.children[0].children[0].menuUrl}`
    }
  }
  return path
}