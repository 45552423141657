import db from '@/utils/localStorage'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { encrypt } from "@/utils/encrypt"
import router, { resetRouter } from '@/router'
import { filterAsyncRoutes } from '@/utils/index'

import { login, logout, chooseUserAccount, getUserInfo } from "@/api/login/auth"

const state = {
  token: getToken(),
  loginInfo: db.get('LOGIN_INFO'),
  userInfo: db.get('USER_INFO'),
  routes: [],
  tabs: []
}

const mutations = {
  SET_TOKEN(state, val) {
    setToken(val)
    state.token = val
  },
  REMOVE_TOKEN(state) {
    removeToken()
    state.token = ''
  },
  SET_LOGIN_INFO(state, val) {
    const time = new Date().getTime()
    // 每次刷新完token后，需重置token有效期
    val.token_expires_time = time + val.expires_in * 1000 * 0.8
    // refreshToken时间，以首次登录时间开始计算。刷新token不会重置refreshToken的有效时间
    const loginInfo = db.get('LOGIN_INFO')
    if (loginInfo) {
      val.refresh_token_expires_time = loginInfo.refresh_token_expires_time
    } else {
      val.refresh_token_expires_time = time + val.refresh_expires_in  * 1000 * 0.8
    }
    db.set('LOGIN_INFO', val)
    state.loginInfo = val
  },
  SET_USER_INFO(state, val) {
    db.set('USER_INFO', val)
    state.userInfo = val
  },
  SET_ROUTES(state, val) {
    state.routes = val
  },
  SET_TABS(state, val) {
    state.tabs = val
  }
}

const actions = {
  async login({ commit }, data) {
    // sm2 加密
    const params = {
      username: encrypt(data.username),
      password: encrypt(data.password),
    }
    const [res] = await login(params)
    if (res) {
      commit('SET_TOKEN', res.access_token)
      commit('SET_LOGIN_INFO', res)
      return true
    }
    return false
  },
  async chooseUserAccount({ dispatch }, data) {
    const [res] = await chooseUserAccount({ id: data.id })
    if (res) {
      dispatch('setUserInfo', res)
      return true
    } else {
      return false
    }
  },

  async getUserInfo({ dispatch }) {
    const [res] = await getUserInfo()
    if (res) {
      dispatch('setUserInfo', res)
      return true
    } else {
      return false
    }
  },

  logout({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      logout().then(()=> {
        commit('REMOVE_TOKEN')
        resetRouter()
        dispatch('removeTabs')
        db.clear()
        resolve()
      }).catch((error)=> {
        reject(error)
      })
    })
  },

  setUserInfo({ commit, dispatch }, data) {
    let result = data
    let routes = []
    if (data.menuList) {
      let menuList = typeof data.menuList === 'string' ? JSON.parse(data.menuList) : data.menuList
      result.menuList = filterMenuList(menuList)
      routes = filterAsyncRoutes(result.menuList)
    }
    commit('SET_USER_INFO', result)
    commit('SET_ROUTES', routes)
    resetRouter()
    dispatch('removeTabs')
    routes.map(item => {
      router.addRoute(item)
    })
  },
  addTab({ commit, state }, route) {
    if (route.name) {
      if (state.tabs.some(v => v.path === route.path)) return
      let newTab = Object.assign({ ...route }, { title: route.meta.title })
      state.tabs.push(newTab)
      commit('SET_TABS', state.tabs)
    }
  },
  delTab({ commit, state }, name) {
    state.tabs.map((item, index) => {
      if (item.name === name) state.tabs.splice(index, 1)
    })
    commit('SET_TABS', state.tabs)
  },
  removeTabs({ commit}) {
    commit('SET_TABS', [])
  }
}

// 过滤非菜单
function filterMenuList(arr) {
  let data = []
  arr.map((item)=> {
    if (item.menuType === '10') {
      if (item.children.length) {
        item.children = filterMenuList(item.children)
      }
      data.push(item)
    }
  })
  return data
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
