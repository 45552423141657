<template>
  <div v-if="$route.name !== 'workbench'" class="tabs">
    <el-tabs ref="tabs" v-model="tabValue" type="card" @tab-click="handleClick" @tab-remove="removeTab">
      <el-tab-pane v-for="item in tabs" :key="item.name" :label="item.title" :name="item.name" :closable="tabs.length>1">
        <em slot="label">{{item.title}}</em>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    currentIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tabValue: this.$route.name
    }
  },
  computed: {
    tabs() {
      return this.$store.getters.tabs
    }
  },
  watch: {
    $route(val) {
      this.tabValue = val.name
    }
  },
  mounted() {
    this.initArrow()
  },
  methods: {
    // 初始化左右箭头
    initArrow() {
      const _this = this
      if (_this.$refs.tabs?.$el) {
        const elTabsNavWrap = _this.$refs.tabs.$el.getElementsByClassName('el-tabs__nav-wrap')[0]
        const iconArrowLeft = document.createElement('i')
        iconArrowLeft.className = 'el-icon-arrow-left'
        const navPrev = document.createElement('span')
        navPrev.className = 'el-tabs__nav-prev disabled'
        navPrev.appendChild(iconArrowLeft)
        elTabsNavWrap.prepend(navPrev)

        const iconArrowRight = document.createElement('i')
        iconArrowRight.className = 'el-icon-arrow-right'
        const navNext = document.createElement('span')
        navNext.className = 'el-tabs__nav-next disabled'
        navNext.appendChild(iconArrowRight)
        elTabsNavWrap.prepend(navNext)
      }
    },
    // 点击tab
    handleClick(tab) {
      const filterTabs = this.tabs.filter(v => v.name === tab.name)
      this.$router.push(filterTabs[0])
      this.$emit('update:currentIndex', filterTabs[0].meta.topIndex)
    },
    // 删除
    async removeTab(targetName) {
      if (this.tabs.length === 1) {
        return
      }
      if (targetName === this.tabValue) {
        const delIndex = this.tabs.findIndex(v => v.name === targetName)
        this.tabValue = this.tabs[delIndex-1].name
        this.$router.push(this.tabs[delIndex-1])
      }
      await this.$store.dispatch('user/delTab', targetName)
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  position: relative;
  height: 40px;
  background: #FFFFFF;
  box-shadow: 2px 4px 4px 0 rgba(0,0,0,0.02);
  border-left: 1px solid #e5e5e5;
}
::v-deep .el-tabs--card {
  .el-tabs__header {
    border-bottom: 0;
    .el-tabs__nav-wrap {
      padding: 0 25px;
      > span {
        width: 24px;
        line-height: 40px;
        color: #777;
        text-align: center;
        z-index: 1;
        i:before {
          font-family: "iconfont";
        }
        &:hover {
          color: #222;
        }
        &.disabled {
          color: #ccc;
          cursor: not-allowed;
        }
        &.el-tabs__nav-prev {
          border-right: 1px solid #e5e5e5;
          .el-icon-arrow-left:before {
            content: "\e8cf";
          }
        }
        &.el-tabs__nav-next {
          border-left: 1px solid #e5e5e5;
          .el-icon-arrow-right:before {
            content: "\e8d0";
          }
        }
      }
      .el-tabs__nav {
        padding: 0 4px;
        border: 0;
        .el-tabs__item {
          padding: 0 12px !important;
          border: 0;
          &:before {
            position: absolute;
            top: 8px;
            left: 4px;
            width: calc(100% - 8px);
            height: calc(100% - 16px);
            content: "";
            background-color: #f5f5f5;
            border-radius: 4px;
          }
          em {
            position: relative;
            top: -1px;
            font-size: 12px;
            color: #777;
          }
          .el-icon-close {
            position: absolute;
            top: 3px;
            right: -1px;
            display: none;
            width: 10px;
            height: 10px;
            line-height: 8px;
            color: #fff;
            background-color: #999;
            border: 1px solid #fff;
            &:before {
              margin-left: -2px;
              transform: scale(0.5);
            }
          }
          &:hover {
            &:before {
              background-color: #E6F5EE;
            }
            em {
              color: #222;
            }
            .el-icon-close {
              display: inline-block;
            }
          }
          &.is-active {
            &:before {
              background-color: #09A057;
            }
            em {
              color: #fff;
            }
            .el-icon-close {
              display: inline-block;
              color: #fff;
              background-color: #E45141;
              border: 1px solid #fff;
            }
          }
        }
      }
    }
  }
  .el-tabs__content {
    display: none;
  }
}
</style>
