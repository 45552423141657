const db = {
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  get(key, defaultValue = '') {
    if (JSON.parse(localStorage.getItem(key)) === 0) {
      return JSON.parse(localStorage.getItem(key))
    }
    return JSON.parse(localStorage.getItem(key)) || defaultValue
  },
  remove(key) {
    localStorage.removeItem(key)
  },
  clear() {
    localStorage.clear()
  }
}

export default db
