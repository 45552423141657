import db from './localStorage'

const TokenKey = 'TOKEN'

/**
 * 获取token
 *
 * @returns {String} token
 */
export function getToken() {
  return db.get(TokenKey, '')
}

/**
 * 写入token
 *
 * @returns {String} token
 */
export function setToken(token) {
  return db.set(TokenKey, token)
}

/**
 * 删除token
 *
 * @returns {String} token
 */
export function removeToken() {
  return db.remove(TokenKey)
}
