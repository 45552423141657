import Vue from 'vue'
import ElementUI from "element-ui"
import App from './App.vue'
import router from './router'
import store from './store'

import './permission' // 鉴权

import '@/assets/styles/common.scss'
const isProd = process.env.NODE_ENV === 'production'

Vue.config.productionTip = false
Vue.prototype.$isProd = isProd
Vue.use(ElementUI)


// 导入qiankun内置函数
import { registerMicroApps, start } from 'qiankun'

// 注册qiankun微应用
registerMicroApps([
  {
    name: 'system', // 微应用名称：系统管理
    entry: process.env.VUE_APP_SYSTEM_ENTRY, // 微应用入库地址
    container: '#appContainer', // 主应用容器
    activeRule: '/system', // 主应用路由匹配规则
    props: {
      routerBase: '/system',
      router,
      store
    } // 主应用传递给微应用的参数
  },
  {
    name: 'cnms', // 微应用名称：碳中和管理
    entry: process.env.VUE_APP_CNMS_ENTRY, // 微应用入库地址
    container: '#appContainer', // 主应用容器
    activeRule: '/cnms', // 主应用路由匹配规则
    props: {
      routerBase: '/cnms',
      router,
      store
    } // 主应用传递给微应用的参数
  }
])

// 启动qiankun
start({
  sandbox: {
    experimentalStyleIsolation: true // 开启沙箱，样式隔离
  },
  prefetch: isProd // 不开启预加载微应用
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
