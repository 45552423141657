import { sm2 } from "sm-crypto";

// 请求后端SM2加密
export function encrypt(data) {
  let msg = data
  if (typeof data !== 'string') {
    msg = JSON.stringify(data)
  }
  const publicKey = '04739ded6f65976e3ec66a2d01bb44b7ce35f49e3ecd10be4ca6b3fd2b76529a81e275f0314cdb52a19b15446fe7759cc1a41dfdc9abaee6d3436033f4d107456f'
  const encryptData = sm2.doEncrypt(msg, publicKey)
  return encryptData
}


export function doEncrypt(data) {
  let msg = data
  if (typeof data !== 'string') {
    msg = JSON.stringify(data)
  }
  const publicKey = '041789b2a7b776ae89f3516a6e3c8d6a6f37505668cdee022722d2626d8e23a695a6feadfbbbbb81e56626713a35d52c9ccdffffc5cb85a3a338cd2a50e91917d6'
  const encryptData = sm2.doEncrypt(msg, publicKey)
  return encryptData
}

export function doDecrypt(data) {
  const privateKey = '75063951f9433bd4e7373f4f21b56f17ab31f6e4674cc25b170b83f2a649af36'
  const decryptData = sm2.doDecrypt(data, privateKey)
  return decryptData
}