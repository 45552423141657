<template>
  <div ref="appWrap" class="app-wrap">
    <!-- 框架 -->
    <sidebar :username="username" :menuList="menuList" :currentIndex.sync="currentIndex" :isFoldSub.sync="isFoldSub" />
    <submenu v-if="!isFoldSub && currentIndex !== null" :submenuList="menuList[currentIndex].children" :isFoldSub.sync="isFoldSub" />
    <div class="app-body">
      <tabs :currentIndex.sync="currentIndex" />
      <div class="app-main">
        <router-view />
        <!-- 微应用容器 -->
        <div v-if="$route.meta.module !== 'mainApp'" id="appContainer" />
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from './components/sidebar'
import submenu from './components/submenu'
import tabs from './components/tabs'
import db from '@/utils/localStorage'

export default {
  name: 'Layout',
  components: {
    sidebar,
    submenu,
    tabs
  },
  data() {
    return {
      username: db.get('USER_INFO').realName,
      menuList: db.get('USER_INFO').menuList,
      currentIndex: 0,
      isFoldSub: false
    }
  },
  mounted() { 
    window.addEventListener('load', () => {
      this.currentIndex = db.get('CURRENT_INDEX')
    })
  }
}
</script>

<style lang="scss" scoped>
.app-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  overflow: hidden;
}
.app-body {
  flex: 1;
  overflow: hidden;
  .app-main { 
    height: calc(100% - 72px);
    margin: 16px;
    overflow: auto;
  }
}
#appContainer {
  height: 100%;
}
</style>