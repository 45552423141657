<template>
  <div class="submenu" v-if="!$route.meta.hiddenMenu">
    <!-- 搜索 -->
    <div class="search">
      <el-input v-model="filterText" placeholder="输入关键词搜索..." prefix-icon="iconfont icon-search" clearable />
    </div>

    <!-- 子菜单 -->
    <el-scrollbar>
      <el-menu class="el-menu-vertical-demo" :default-active='$route.path' router :default-openeds="defaultOpeneds">
        <template v-for="(item, index) in filterMenuList">
          <el-menu-item v-if="!item.children.length" :key="index" :index="joinPath(item)">
            <i :class="item.menuIcon ? `icon-submenu-${item.menuIcon}` : 'icon-submenu-default'" />
            <span>{{ item.menuName }}</span>
          </el-menu-item>

          <el-submenu v-else :key="index" :index="`/${index}`">
            <template slot="title">
              <i :class="item.menuIcon ? `icon-submenu-${item.menuIcon}` : 'icon-submenu-default'" />
              <span>{{ item.menuName }}</span>
            </template>
            <el-menu-item v-for="(subItem, ind) in item.children" :key="ind" :index="joinPath(subItem, item)" v-show="subItem.showFlag === 1">
              {{ subItem.menuName }}
            </el-menu-item>
          </el-submenu>
        </template>
      </el-menu>
    </el-scrollbar>

    <!-- 收起 -->
    <div class="btm">
      <span class="btn-fold" @click.stop="foldMenu"><i class="icon-fold" />收起</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Submenu',
  props: {
    submenuList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filterText: '',
      filterMenuList: [],
      defaultOpeneds: [],
      isFoldSub: false
    }
  },
  watch: {
    submenuList: {
      handler(val) {
        this.filterMenuList = val
        if (!this.$route.meta.hiddenMenu) {
          this.defaultOpeneds = []
          val.map((item, index) => {
            this.defaultOpeneds.push(`/${index}`)
          })
        }
      },
      immediate: true
    },
    filterText(val) {
      this.filterMenu(val)
    }
  },
  methods: {
    // 关键字搜索
    filterMenu(val) {
      let isLeaf = false
      this.filterMenuList = this.doFilter(this.submenuList, val, isLeaf).filter(item =>
          item.children.length > 0 || (item.children.length == 0 && item.menuName.indexOf(val) != -1))
    },
    doFilter(arr, val, isLeaf) {
      return arr
        .filter(item => {
          if (!isLeaf) {
            return true
          }
          return item.menuName.indexOf(val) != -1
        })
        .map(item => {
          item = Object.assign({}, item)
          if (item.children) {
            item.children = this.doFilter(item.children, val, true)
          }
          return item
        })
    },
    // 收起
    foldMenu() {
      this.$emit('update:isFoldSub', true)
    },
    // 拼接路由
    joinPath(item, parentItem) {
      const base = item.module === 'mainApp' ? '' : `/${item.module}`
      const parentPath = parentItem ? `/${parentItem.menuUrl}` : ''
      const path = item.urlParams ? `/${item.menuUrl}?${item.urlParams}` : `/${item.menuUrl}`
      return base + parentPath + path
    }
  }
}
</script>

<style lang="scss" scoped>
.submenu {
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  color: #777;
  background: #f3f4f5 url(@/assets/images/submenu.jpg) no-repeat 0 100%;
  box-shadow: 4px 2px 4px 0 rgba(0, 0, 0, 0.04);
  .search {
    padding: 8px 8px 7px;
    ::v-deep .el-input {
      font-size: 12px;
      &__inner {
        height: 28px;
        padding-left: 7px;
        line-height: 28px;
      }
      &__prefix {
        right: 8px;
        left: auto;
      }
      &__suffix {
        right: 20px;
      }
      &__icon {
        line-height: 28px;
        &.icon-search {
          font-size: 12px;
          color: #999;
        }
      }
    }
  }
  ::v-deep .el-scrollbar {
    width: 100%;
    height: calc(100% - 79px);
    &__wrap {
      overflow-x: hidden;
    }
  }
  ::v-deep .el-menu {
    background: transparent;
    border: none;
    &-item,
    .el-submenu__title {
      height: 40px;
      line-height: 40px;
      padding: 0 0 0 13px !important;
      span,
      i {
        color:#777;
      }
      i[class*="icon-submenu-"] {
        display: inline-block;
        width: 12px;
        margin-right: 11px;
        font-family: "iconfont";
        font-size: 12px;
      }
    }
    .el-submenu {
      &__icon-arrow {
        right: 8px;
        margin-top: -5px;
      }
      .el-menu-item {
        height: 36px;
        min-width: 100%;
        padding: 0 20px 0 36px !important;
        line-height: 38px;
        color: #777;
        &:before {
          position: absolute;
          top: 50%;
          left: 16px;
          display: inline-block;
          width: 4px;
          height: 4px;
          margin-top: -1px;
          background: #777;
          border-radius: 50%;
          content: "";
        }
        &:hover {
          background-color: #fff;
        }
        &.is-active {
          color: #222;
          background-color: #fff;
          border-right: 2px solid#09A057; 
          &:before {
            background: #222;
          }
        }
      }
      &.is-opened {
        span,
        i {
          color: #222;
        }
      }
    }
  }
  .btm {
    display: block;
    width: 100%;
    height: 36px;
    padding-left: 12px;
    line-height: 34px;
    background-color: #fcfcfc;
    border-top: 1px solid #e5e5e5;
    .btn-fold {
      cursor: pointer;
      i {
        margin-right: 8px;
        font-family: "iconfont";
        font-size: 10px;
      }
    }
  }
}
</style>
