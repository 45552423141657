import request from './request'
import { Message } from 'element-ui'

// 提示信息显示时长
const messageDuration = 5 * 1000

/**
 * 通用请求方法
 * @param {method} 请求方式
 * @param {url} 接口名称
 * @param {data} 传参
 * @param {contentType} 请求头类型
 * @param {hasStatusJudge} 是否有业务状态判断
 * @param {hasErrorMsg} 是否有异常错误信息提示弹窗
 */
export function ajaxRequest ({ method, url, data, contentType, hasStatusJudge = true, hasErrorMsg = true }) {
  method = method.toUpperCase()
  data = data || ''
  let requestConfig = {
    method: method,
    url: url,
    headers: {
      'Content-Type': contentType || 'application/json;'
    }
  }
  switch (method) {
    case 'POST':
    case 'PUT':
    case 'DELETE':
      requestConfig.data = data
      break
    case 'GET':
      requestConfig.params = cleanObjectEmpty(data)
      break
    case 'UPLOAD':
      requestConfig.method = 'POST'
      requestConfig.headers = { 'Content-Type': 'multipart/form-data' }
      requestConfig.data = data
      break
    case 'DOWNLOAD':
      requestConfig.method = 'POST'
      requestConfig.data = data
      requestConfig.responseType = 'blob'
      break
    case 'DOWNLOAD_GET':
      requestConfig.method = 'GET'
      requestConfig.params = cleanObjectEmpty(data)
      requestConfig.responseType = 'blob'
      break
    default:
      requestConfig.method = 'POST'
      requestConfig.data = data
      break
  }
  return new Promise((resolve, reject) => {
    request(requestConfig)
      .then(res => {
        // 文件下载请求，以及设置了不进行业务状态判断，会直接抛出返回值
        const download = ['DOWNLOAD', 'DOWNLOAD_GET']
        if (download.includes(method) || !hasStatusJudge) {
          resolve(res)
          return
        }
        const result = res.data
        // 业务请求状态为0时，操作成功
        if (result.code === 0) {
          resolve([isEmpty(result.data)])
        } else {
          // 异常错误信息提示弹窗
          if (hasErrorMsg) {
            Message({
              type: 'error',
              message: result.message,
              duration: messageDuration
            })
          }
          resolve([null, result])
        }
      }).catch(err => {
        reject(err)
      })
  })
}

/**
 * 清楚数组中值为空的字段
 * @param {*} object 
 * @returns 
 */
const cleanObjectEmpty = (object) => {
  if (!(object instanceof Object)) {
    return object
  }
  const result = { ...object }
  Object.keys(result).forEach(key => {
    const value = result[key]
    if (value === '' || value === null || value === undefined) {
      delete result[key]
    }
  })
  return result
}

/**
 * 判空
 * @param {*} data 
 * @returns 
 */
const isEmpty = (data) => {
  if (data === '' || data === null || data === undefined) {
    return {}
  }
  return data
}
