import axios from 'axios'
import router from '@/router'
import db from './localStorage'
import { getToken, removeToken } from './auth'
import store from '@/store'

import MessageOnce from './messageOnce'
const messageOnce = new MessageOnce()

// 请求超时时间
const requestTimeOut = 60 * 1000
// 提示信息显示时长
const messageDuration = 5 * 1000

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: requestTimeOut,
  withCredentials: false
})
service.interceptors.request.use(
  config => {
    if (getToken()) {
      // 判断token是否过期，过期则刷新token
      const status = isTokenValid()
      if (status === 'success') {
        // 请求头统一增加token
        config.headers['Authorization'] = 'bearer ' + getToken()
        const menuId = router?.currentRoute?.meta?.id
        if (menuId) {
          config.headers['menuId'] = menuId
        }
        return config
      } else if (status === 'fail') {
        return refreshToken().then(newToken => {
          // 刷新 token 成功后，重新设置请求头
          config.headers['Authorization'] = 'bearer ' + newToken
          return config
        }).catch(() => {
          return Promise.reject('服务异常！')
        })
      } else {
        return Promise.reject('服务异常！')
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.log('无效的token或者token过期，触发刷新token机制！');
          // 无效的token或者token过期，触发刷新token机制，然后再次发起原请求
          return refreshToken().then(newToken => {
            // 刷新 token 成功后，重新设置请求头
            error.config.headers['Authorization'] = 'bearer ' + newToken
            return axios(error.config);  
          }).catch(() => {
            return Promise.reject(error)
          })
        case 403:
          messageOnce.error({
            message: '拒绝访问，暂无该操作权限',
            type: 'error',
            duration: messageDuration
          })
          break
        case 404:
          messageOnce.error({
            message: '请求错误,未找到该资源',
            type: 'error',
            duration: messageDuration
          })
          break
        default:
          messageOnce.error({
            message: error.response.data?.message || '系统内部异常，请联系网站管理员',
            type: 'error',
            duration: messageDuration
          })
          break
      }
    } else {
      messageOnce.error({
        message: '请求服务器异常！',
        type: 'error',
        duration: messageDuration
      })
    }
    if (error.message?.includes('timeout')) {
      messageOnce.error({
        message: '请求服务器超时',
        type: 'error',
        duration: messageDuration
      })
    }
    return Promise.reject(error)
  }
)

/**
 * 刷新token
 */
function refreshToken() {
  return new Promise((resolve, reject) => {
    const info = db.get('LOGIN_INFO')
    axios.post(process.env.VUE_APP_BASE_API + '/auth/token/refresh', '', {
      headers: {
        'Authorization': 'bearer ' + info.refresh_token
      }
    }).then(res => {
      if (res.status === 200 && res.data.code === 0) {
        // 刷新 token 成功后，更新token与登录信息存储
        store.commit('user/SET_LOGIN_INFO', res.data.data)
        store.commit('user/SET_TOKEN', res.data.data.access_token)
        resolve(res.data.data.access_token)
      } else {
        tokenError()
        reject()
      }
    }).catch(() => {
      tokenError()
      reject()
    })
  })
}

function tokenError() {
  messageOnce.error({
    message: '登录已过期，请重新登录',
    type: 'info',
    duration: messageDuration
  })
  removeToken()
  db.clear()
  router.replace('/login')
}

/**
 * 判断token是否过期
 */
function isTokenValid() {
  const info = db.get('LOGIN_INFO')
  if (info) {
    const nowTime = new Date().getTime()
    // 刷新token的有效时间过期，则需重新登录
    if (nowTime > info.refresh_token_expires_time) {
      console.log('Exceed the refresh_token expires_time!');
      tokenError()
      return 'error'
    }
    // token有效时间过期，则需重新刷新token
    if (nowTime > info.token_expires_time) {
      console.log('Exceed the token expires_time!');
      return 'fail'
    }
  }
  return 'success'
}

export default service
