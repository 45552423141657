import router from './router'
import store from './store'
import db from './utils/localStorage'
import { getToken } from './utils/auth'
import getPageTitle from './utils/getPageTitle'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login'] // 鉴权白名单

router.beforeEach(async(to, from, next) => {
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  const hasToken = getToken()
  if (hasToken) {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
      NProgress.done()
    } else {
      const asyncRouter = store.getters.routes
      if (asyncRouter.length) {
        // 有动态路由，正常跳转
        await store.dispatch('user/addTab', to)
        next()
      } else {
        // 没有动态路由，再次调setUserInfo添加路由
        try {
          await store.dispatch('user/setUserInfo', db.get('USER_INFO'))
          await store.dispatch('user/addTab', to)
          next({ ...to, replace: true })
        } catch (error) {
          await store.dispatch('user/logout')
          next('/login')
          NProgress.done()
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      next('/login')
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})